<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      mura portal
      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />

<!-- <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" /> -->
      </div>

      <v-spacer></v-spacer>
      <v-menu offset-y v-if="isSignedIn">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon> {{user}}さん<v-icon>mdi-triangle-small-down</v-icon>
        </v-btn>
          <!-- <v-btn style="text-transform: none" v-bind="attrs" v-on="on">
            {{ user }}さん
          </v-btn> -->
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title @click="logout">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- <amplify-greetings></amplify-greetings> -->
    </v-app-bar>

    <v-main>
      <template>
        <router-view />
      </template>

    </v-main>
  </v-app>
</template>

<script>

// import Auth from "@aws-amplify/auth"
// import store from "@/store/index.js";

import { Auth } from 'aws-amplify';
export default {
  name: 'App',

  data: () => ({
    //    user: undefined,
  }),

  created: async function () {
    //    this.user = await Auth.currentAuthenticatedUser();
    //    console.log(this.user.username);
  },

  methods: {
    async logout() {
      console.log("logout");
      try {
        await Auth.signOut();
        this.$store.commit("setUser", null);
        this.$router.push({name: "SignIn"});

      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  },


  computed: {
    user() {
      if (this.$store.state.user) {
        return this.$store.state.user.username;
      } else {
        return null;
      }
    },
    isSignedIn() {
      return this.$store.state.user !== null;
    },
  }

};
</script>
