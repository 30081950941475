<template>
  <div>
    <template>
      <amplify-authenticator>
        <amplify-sign-in header-text="ログインしてください" hide-sign-up=true submit-button-text="ログイン" slot="sign-in">
        </amplify-sign-in>
      </amplify-authenticator>
    </template>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
  name: "SignIn",

  data: () => ({
    user: undefined,
    authState: undefined,
    unsubscribeAuth: undefined,

    formFields: [
      {
        type: 'email',
        label: 'Custom Email Label',
        placeholder: 'Custom email placeholder',
        inputProps: { required: true, autocomplete: 'username' },
      },
      {
        type: 'password',
        label: 'Custom Password Label',
        placeholder: 'Custom password placeholder',
        inputProps: { required: true, autocomplete: 'new-password' },
      },
      {
        type: 'phone_number',
        label: 'Custom Phone Label',
        placeholder: 'Custom phone placeholder',
      },
    ]
  }),
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
    console.log("this.authState");
    console.log(this.authState);
    console.log("this.user");
    console.log(this.user);
    console.log("this.unsubscribeAuth");
    console.log(this.unsubscribeAuth);
  },

  beforeDestroy() {
    this.unsubscribeAuth();
  },

};
</script>