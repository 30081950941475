import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from "@aws-amplify/auth";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
// import SignIn from '../views/SignIn.vue'

import store from "@/store/index.js";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requireAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/tweet',
    name: 'Tweet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tweet.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/signin',
    name: 'SignIn',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signin" */ '../views/SignIn.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


function getAuthenticatedUser() {
  return Auth.currentAuthenticatedUser()
    .then((data) => {
      if (data && data.signInUserSession) {
        store.commit("setUser", data);
        return data;
      }
    })
    .catch((e) => {
      console.error(e);
      store.commit("setUser", null);
      return null;
    });
}

let user;

router.beforeResolve(async (to, from, next) => {
  user = await getAuthenticatedUser();
  console.log("user at route");
  console.log(user);

  if (to.name === "SignIn" && user) {
    console.log("router.beforeResolve1")
    return next({ name: "Home" });
  }

  if (to.matched.some((record) => record.meta.requireAuth) && !user) {
    console.log("router.beforeResolve2")
    return next({ name: "SignIn" });
  }
  return next();
});

onAuthUIStateChange((authState, authData) => {
  console.log(router.history.current.path);
  if (authState === AuthState.SignedIn && authData) {
    console.log("onAuthUIStateChange1")
    // console.log("this.$route.path");
    // console.log(this.$route.path);
    router.push({ path: '/' });
  }
  if (authState === AuthState.SignedOut) {
    console.log("onAuthUIStateChange2")
    router.push({ name: "SignIn" });
  }
});


export default router
