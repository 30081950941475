<template>
  <v-container>
    <v-row class="input_tweet">
      <v-col cols="6">
        <v-text-field label="tweet" v-model="newTweet" class="mt-4"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field label="category" v-model="newCategory" class="mt-4"></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-btn color="primary" class="mt-4" @click="setTweets(user, newCategory, newTweet)">登録</v-btn>
      </v-col>
    </v-row>

    <v-row ref="tweet" class="tweet_content">
      <v-container v-for="(item, index) in tweets" v-bind:key="item.index">
        <v-chip v-if="item.category" color="primary" outlined small>{{item.category}}</v-chip>
        <v-chip v-else color="primary" outlined small>未分類</v-chip>
        <div class="balloon_l">
          <div class="says">{{ item.content }}
          </div>
          <!-- <p class="says">{{ item.content }}
          </p>
          <div class="category">todo</div> -->

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="transparent" v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showAdditonalCommnet(index)">コメント</v-list-item>
              <v-list-item @click="changeTweetsStatus(item, 'done')">完了</v-list-item>
              <v-list-item @click="deleteTweets(item)">削除</v-list-item>
              <!-- <v-list-item v-for="(item, index) in items" :key="index">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
          <!-- <span class="ballon_date">{{ item.timestamp | datetimeFormat }}</span> -->
          <div>
            <p class="ballon_date">{{ item.timestamp | datetimeFormat }}</p>
          </div>
          <!-- <div>
            <v-btn class="mx-2 inline_block" fab dark small color="primary" @click="showAdditonalCommnet(index)">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
            <v-btn class="mx-2 inline_block" fab dark small color="primary" @click="deleteTweets(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div> -->
        </div>
        <v-row v-if="index === addIndex">
          <v-text-field v-model="newSubTweet" class="ml-5"></v-text-field>
          <v-btn color="primary" @click="addSubTweets(newSubTweet, index)">登録</v-btn>
        </v-row>

        <!-- <div>
            <v-btn class="mx-2 inline_block" fab dark small color="primary" @click="showAdditonalCommnet(index)">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
            <v-btn class="mx-2 inline_block" fab dark small color="primary" @click="deleteTweets(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div> -->
        <div v-if="item.comments && item.comments.length > 0" class="sub">
          <div v-for="comment in item.comments" :key="comment.timestamp" class="sub_balloon_l">
            <p class="sub_says">
              {{ comment.content }}
            </p>
            <span class="ballon_date" v-if="comment.timestamp">{{ comment.timestamp | datetimeFormat }}</span>

          </div>

          <!-- <span class="ballon_date">{{ comment.timestamp }}</span> -->
          <!-- <span class="ballon_date" v-if="comment.timestamp">{{ comment.timestamp | datetimeFormat }}</span> -->
          <!-- {{comment}} -->
        </div>

        <!-- <v-btn class="mx-2" fab dark small color="primary" @click="showAdditonalCommnet(index)">
          <v-icon>mdi-keyboard-return</v-icon>
        </v-btn>
        <v-btn class="mx-2" fab dark small color="primary" @click="deleteTweets(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-container>
    </v-row>
    <!-- <v-row  ref="tweet">trail</v-row> -->
  </v-container>
</template>

<script>
// import axios from "axios";
import * as api from "@/api";

export default {
  name: "Tweet",

  data: () => ({
    tweets: [],
    newTweet: null,
    newCategory: null,

    addIndex: null,
    newSubTweet: null,

    items: [{ title: "コメント" }, { title: "削除" }],

  }),

  created: async function () {
    this.tweets = await api.getTweets();
    console.log(this.tweets);
    console.log(this.tweets[0].content);


    //    this.tweets = JSON.parse(data.body);
    //    console.log(this.tweets.body.contents);

    // this.tweets = [
    //   {
    //     category: "todo", content: "四葉のクローバーのストラップ作成", owner: "masa", timestamp: 1653369604,
    //     comments: [
    //       { content: "4つばのクローバー探し", timestamp: 1653369609 },
    //       { content: "ストラップ購入", timestamp: 1653369614 },
    //     ]
    //   },
    //   {
    //     category: "todo", content: "免許更新", owner: "masa", timestamp: 1653369604,
    //   },
    //   {
    //     category: "todo", content: "弘樹の英語文法", owner: "masa", timestamp: 1653369604,
    //     comments: []
    //   },
    // ];
  },

  methods: {
    async setTweets(user, category, content) {
      let result = await api.setTweets(user, category, content);
      this.tweets.unshift(result);
      this.newTweet = null;
      this.newCategory = null;
      // let contents = document.getElementById('tweet_contents');
      // console.log("contents");
      // console.log(contents.scrollTop);
      // contents.scrollTop = 0;
    },

    async updateTweets(tweet) {
      console.log(tweet);
      tweet.date = new Date();
      await api.updateTweets(tweet);
    },


    async changeTweetsStatus(tweet, status) {
      console.log(tweet);
      tweet.date = new Date();
      await api.changeTweetsStatus(tweet, status);
    },

async deleteTweets(tweet) {
      console.log(tweet);

      let result = await api.deleteTweets(tweet);
      console.log(result);
      let index = this.tweets.findIndex(tweet => tweet.timestamp === result.timestamp);
      this.tweets.splice(index, 1);
    },

    showAdditonalCommnet(index) {
      this.addIndex = index;
    },

    async addSubTweets(newSubTweet, index) {
      if (!this.tweets[index].comments) this.tweets[index].comments = [];
      this.tweets[index].comments.push({ content: newSubTweet, timestamp: Math.floor(new Date() / 1000) });
      console.log(this.tweets);
      await api.addSubTweets(this.tweets[index]);
      this.newSubTweet = null;
      this.addIndex = null;
    },


  },

  computed: {
    user() {
      if (this.$store.state.user) {
        return this.$store.state.user.username;
      } else {
        return null;
      }
    },
  },


  filters: {
    datetimeFormat: function (value) {
      if (!value) return '';
      if (typeof value === "string") value = Number(value);
      let datetime = new Date(value * 1000)
      return datetime.getFullYear() + "年"
        + (("0" + (datetime.getMonth() + 1)).slice(-2)) + "月"
        + (("0" + datetime.getDate()).slice(-2)) + "日"
        + " "
        + (("0" + datetime.getHours()).slice(-2))
        + ":"
        + (("0" + datetime.getMinutes()).slice(-2));
    }
  }
};
</script>

<style scoped>
/*吹き出し*/
.balloon_l,
.balloon_r {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /*縦位置を上揃え*/
  /*   align-items: center; */
  /*縦位置を真ん中揃え*/
}

.sub_balloon_l,
.sub_balloon_r {
  margin: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /*縦位置を上揃え*/
  /*   align-items: center; */
  /*縦位置を真ん中揃え*/
}


.balloon_r {
  justify-content: flex-end;
}

.faceicon img {
  width: 80px;
  /*任意のサイズ*/
  height: auto;
}

.balloon_r .faceicon {
  margin-left: 25px;
}

.balloon_l .faceicon {
  margin-right: 25px;
}

.sub_balloon_l .faceicon {
  margin-right: 25px;
}


.balloon_r .faceicon {
  order: 2 !important;
}

.says {
  max-width: 500px;
  /*最大幅は任意*/
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 17px 13px 15px 18px;
  border-radius: 12px;
  background: #99dddd;
  /*色は任意*/
  box-sizing: border-box;
  margin: 0 !important;
  line-height: 1.5;
  /*   align-items: center; */
  /* position: relative; */

}

.sub_says {
  max-width: 500px;
  /*最大幅は任意*/
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 5px 13px 5px 18px;
  /*上、右、下、左 */
  border-radius: 12px;
  background: #ffe4e1;
  /*色は任意*/
  box-sizing: border-box;
  /* margin: 0  !important; */
  margin: 1px 5px 1px 10px;
  line-height: 1.5;
  /*   align-items: center; */
  font-size: 80%;
}

.sub {
  margin: 5px 5px 5px 10px;

}

.says p {
  margin: 8px 0 0 !important;
}

/* .sub_says p {
  margin: 8px 0 0 !important;
} */


.says p:first-child {
  margin-top: 0 !important;
}

.sub_says p:first-child {
  margin-top: 0 !important;
}


.says:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  /*   margin-top:-3px;  */
}

.sub_says:after {
  content: "";
  position: absolute;
  border: 10px solid transparent;
  /* border: 10px solid red; */
  /*   margin-top:-3px;  */
}

.balloon_l .says:after {
  left: -26px;
  border-right: 22px solid #99dddd;
}

.sub_balloon_l .sub_says:after {
  /* left: -26px; */
  left: -26px;
  border-right: 22px solid #ffe4e1;
}


.balloon_r .says:after {
  right: -26px;
  border-left: 22px solid #99dddd;
}

.ballon_date {
  padding-left: 20px;
  font-size: 60%;
}

.balloon_comment {
  font-size: 80%;
  max-width: 500px;
  /*最大幅は任意*/
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 5px 5px 5px 18px;
  border-radius: 12px;
  background: #99dddd;
  /*色は任意*/
  box-sizing: border-box;
  margin: 2px 2px 2px 20px !important;
  line-height: 1.5;

}

.test {
  /* display: block; */
  font-size: 80%;
  max-width: 500px;
  background: #ffe4e1;
  border-radius: 6px;
  width: fit-content;
  padding: 5px 5px 5px 5px;
  margin: 2px 2px 2px 20px;
}

.input_tweet {
  position: fixed;
  z-index: 3;
  background-color: #f5f5f5;
  top: 9%;
  height: 100px;
  width: auto;
}

.tweet_content {
  margin: 90px 5px 0px 5px;


}

.inline_block {
  display: inline;
}

.float {
  float: left;
}

.category {
  /* position: absolute; */
  /* top: 0;
  left: -10; */
  margin-bottom: -10;
  padding-bottom: -10;
  font-size: 50%;
  background-color: red;
}
</style>
