import axios from "axios";

// const baseURL = process.env.VUE_APP_HOST;
// (!todo) 暫定 local開発のBackend URL
// const baseURL = "http://localhost:3000";
// (!todo) 暫定 ProductionのBackend URL
// const baseURL = "https://www.masawebsite.net";
 const baseURL = "https://api.masawebsite.net/v2";

//  const rambdaBaseURL = "https://api.masawebsite.net";


const basePath = "api/";

console.log("baseURL");
console.log(baseURL);

const axiosBase = axios.create({
   baseURL
});

// const axiosRambdaBase = axios.create({
//    rambdaBaseURL
// });

export const getHello = async () => {
   try {
      const result = await axiosBase.get(baseURL + "/hello");
      return result.data;
   } catch (error) {
      console.log(error);
   }
};



/************タスクの取得 *************/
export const getTasks = async () => {
   try {
      const result = await axiosBase.get(basePath + "tasks");
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const getTweets = async () => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.get(baseURL + "/tweets");
//      const result = await axios.get("https://api.masawebsite.net/v2/tweets2");
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const setTweets = async (user, category, content) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.post(baseURL + "/tweets",
      {
         content: content,
         category: category,
         owner: user,
      });
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const updateTweets = async (tweet) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.put(baseURL + "/tweets" + "/" + tweet.id.toString(),
//      const result = await axios.put("https://api.masawebsite.net/tweets",
      {
         contents: "update: " + tweet.contents,
         date: tweet.date
      });
      return result.data;
   } catch (error) {
      console.log(error);
   }
};

export const changeTweetsStatus = async (tweet, status) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      console.log(tweet)
      const result = await axios.put(baseURL + "/tweets" + "/" + tweet.timestamp.toString() + "/status",
//      const result = await axios.put("https://api.masawebsite.net/tweets",
      {tweet: {
         status: status,
         timestamp: tweet.timestamp,
         owner: tweet.owner,
      }});
      return result.data;
   } catch (error) {
      console.log(error);
   }
};


export const addSubTweets = async (tweet) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.put(baseURL + "/tweets" + "/" + tweet.timestamp + "/" + "sub_tweets",
//      const result = await axios.put("https://api.masawebsite.net/tweets",
      { tweet });
      return result.data;
   } catch (error) {
      console.log(error);
   }
};


export const deleteTweets = async (tweet) => {
   try {
      // const result = await axiosRambdaBase.get("tweets");
      const result = await axios.delete(baseURL + "/tweets" ,
      { data: {
         "owner": tweet.owner,
         "timestamp": tweet.timestamp

      } });
      return result.data;
   } catch (error) {
      console.log(error);
   }
};
