<template>
  <div class="home">
    <h1>Home</h1>
      <p><router-link to="/">Home</router-link></p>
      <p><router-link to="/about">About</router-link></p>
      <p><router-link to="/tweet">Tweet</router-link></p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  }
}
</script>
